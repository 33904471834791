import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/token.png";

const info = {
  blockExplorer: "https://sepolia.etherscan.io/",
  hardCap: 1,
  minBuy: 0.005,
  maxBuy: 16,
  contractAddress: "0xb2F9042DF2FC8df8fcE642364821F9ccDd6060E8",
  usdtAddress: "0x7Fa362d8d02B88b84000c60f83af5D9e1D3849ce",
  tokenDecimal: 18,
  refPercent: 10,
  referral: false,
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      image: bnb,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
    },
  ],
  buyToken: {
    name: "K9 Clash",
    symbol: "K9K",
    image: tokenImage,
  },
};

export default info;
